<template>
  <!-- v-if="isShowMask" -->
  <div class="mask" v-if="isShowMask">
    <!-- <div class="feedback">
      <div class="title">感谢您的反馈</div>
      <div class="ipt">
        <textarea name="" id="" cols="30" rows="10"></textarea>
      </div>
      <div class="upload">
        <span>上传图片</span>请上传一张,大小不超过5MB的图片
      </div>
      <div class="btn">
        <button>取消</button>
        <button>提交</button>
      </div>
     </div>
     <div class="about">
      <div class="title">关于生活</div>
      <div class="agreement">
        <div class="left"><img src="" alt=""></div>
        <div class="text">用户协议</div>
        <div class="right"><img src="" alt=""></div>
      </div>
      <div class="privacy">
        <div class="left"><img src="" alt=""></div>
        <div class="text">隐私协议</div>
        <div class="right"><img src="" alt=""></div>
      </div>
      <div class="contact">
        <div class="left"><img src="" alt=""></div>
        <div class="text">联系邮箱ensure2024@163.com</div>
        <div class="right"><img src="" alt=""></div>
      </div>
     </div>  -->
    <div class="loginbox">
      <div class="login" v-if="!isScanSuccessful">
        <div class="clo" @click="clo">
          <img src="../assets/del.png" alt="">
        </div>
        <div class="verbox" v-if="isShowtip">
          <div class="verify">
            <div class="ico"><img src="../assets/alert.png" alt=""></div>
            <div class="err">{{ tiptext }}</div>
          </div>
        </div>
        <p class="p1">登录生活AI小帮手账号</p>
        <div class="tab">
          <div class="tabitem" :class="aactiveindex === item.id ? 'active' : ''" v-for="(item, index) in tab"
            :key="item.id" @click="chose(item.id)">{{ item.title }}</div>
          <!-- <div>微信登录</div>
          <div>验证码登录</div> -->
        </div>
        <!-- 微信扫码登录 -->
        <div class="code" v-if="isWei">
          <div id="login_container" ref="loginContainer"></div>
          <div class="codemask" v-if="!isCheck">
            <p class="text1">请先同意勾选</p>
            <p>许可协议和隐私政策</p>
          </div>
        </div>
        <!-- <div class="fform">验证码登录</div> -->
        <div class="bot" v-if="isWei">
          <div class="img"><img src="../assets/wei.png" alt=""></div>
          <div class="text">微信扫码即可完成注册登录</div>
        </div>
        <!-- 验证码登录 -->
        <form action="" v-if="!isWei" @submit.prevent>
          <input type="text" placeholder="请输入手机号" v-model="pnum">
          <input type="text" placeholder="请输入6位数验证码" v-model="ynum">
          <div class="pdel" @click="del" v-if="pnum.length != 0">
            <img src="../assets/pdel.png" alt="">
          </div>
          <button class="get" :class="pnum.trim().length != 0 ? 'active' : ''" v-if="!isSend" @click="send">{{ text
            }}</button>
          <div class="countdown" v-if="isSend">
            <div class="ydel" @click="del2" v-if="ynum.length != 0">
              <img src="../assets/pdel.png" alt="">
            </div>
            <div class="time">{{ time }}s</div>
          </div>
          <button type="submit" :class="[(pnum.length !== 0 && ynum.length !== 0) ? 'active' : '']" @click="yzmlogin"
            @submit.prevent>立即登录</button>
          <!-- <p class="end" @click="clo">稍后设置</p> -->
        </form>
        <div class="privicy">
          <!-- <input type="checkbox" name="" id=""> -->
          <div class="check" @click="changeIscheck">
            <img src="../assets/check.png" alt="" v-if="isCheck">
          </div>
          <p>我已阅读同意生活AI小助手<a href="">许可协议</a>和<a href="">隐私政策</a>，未注册手机登录时会自动创建账号</p>
          <div class="triangle-up" v-if="!isCheck"></div>
          <div class="grey" v-if="!isCheck">请先同意并勾选上诉协议</div>
        </div>
      </div>

      <div class="phonebox" v-if="isScanSuccessful">
        <div class="phone">
          <div class="clo" @click="clo">
            <img src="../assets/del.png" alt="">
          </div>
          <div class="verify" v-if="isShowtip">
            <div class="ico"><img src="../assets/alert.png" alt=""></div>
            <div class="err">验证码错误</div>
          </div>
          <p class="title">绑定手机号</p>
          <div class="intro">应国家法律要求，登录生活A小助手账号前需要验证手机号/邮箱，如果被验证手机号/邮箱未绑定，将自动绑定到当前生活A小助手账号</div>
          <form action="" @submit.prevent>
            <input type="text" placeholder="请输入手机号/邮箱" v-model="pnum">
            <input type="text" placeholder="请输入6位数验证码" v-model="ynum">
            <div class="pdel" @click="del" v-if="pnum.length != 0">
              <img src="../assets/pdel.png" alt="">
            </div>
            <button class="get" :class="pnum.trim().length != 0 ? 'active' : ''" v-if="!isSend" @click="send">{{ text
              }}</button>
            <div class="countdown" v-if="isSend">
              <div class="ydel" @click="del2" v-if="ynum.length != 0">
                <img src="../assets/pdel.png" alt="">
              </div>
              <div class="time">{{ time }}s</div>
            </div>
            <button type="submit" :class="[(pnum.length !== 0 && ynum.length !== 0) ? 'active' : '']" @submit.prevent
              @click="yzmlogin">绑定</button>
            <p class="end" @click="clo">稍后设置</p>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- 近期上线 -->
  <div class="recently" v-if="isShowRecently">

    <div class="retext">
      <div class="guan" @click="clorecently"><img src="../assets/del.png" alt=""></div>
      <div class="rtext"> 近期上线~~</div>
    </div>
  </div>
  <div class="bg">
    <div class="top">
      <div class="ttop">
        <div class="left">
          <div class="img"><img src="../assets/logo.png" alt=""></div>
          <div class="text">生活AI小帮手</div>
        </div>
        <div class="right" v-if="!isLogin">
          <div class="login" @click="login">立即登录</div>
          <!-- <div class="register">免费注册</div> -->
        </div>
        <div class="right2" v-if="isLogin">
          <!-- <div class="nickname">{{ nickname }}</div> -->
          <div class="phone">{{ phone }}</div>
        </div>
      </div>
      <div class="tbottom">
        <div class="title">你好，我是生活AI小帮手</div>
        <div class="intro">有法律、医疗、教育上的问题，都可以问我哦~</div>
      </div>
    </div>
    <div class="middle">
      <div class="item" v-for="(item, index) in tablist" :key="item.id" @click="goPage(item.path)">
        <div class="top12">
          <div class="top1">
            <div class="left">
              <div class="title">{{ item.title }}</div>
              <div class="en">{{ item.en }}</div>
            </div>
            <div class="right">
              <svg t="1724125389717" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="3422" width="32" height="32">
                <path
                  d="M512 960c-247.039484 0-448-200.960516-448-448S264.960516 64 512 64 960 264.960516 960 512 759.039484 960 512 960zM512 128c-211.744443 0-384 172.255557-384 384s172.255557 384 384 384 384-172.255557 384-384S723.744443 128 512 128z"
                  fill="#b11624" p-id="3423"></path>
                <path
                  d="M732.959548 501.152426c-0.032684-0.127295-0.192662-0.25631-0.25631-0.383604-1.536138-3.615858-3.648542-7.071738-6.591802-10.047682-0.032684-0.032684-0.063647-0.032684-0.096331-0.063647-0.032684-0.032684-0.032684-0.063647-0.063647-0.096331l-158.911974-159.359226c-12.480043-12.480043-32.704421-12.576374-45.248112-0.063647-12.512727 12.480043-12.54369 32.735385-0.063647 45.248112l103.328907 103.616181L320 480.00258c-17.664722 0-31.99914 14.336138-31.99914 32.00086s14.336138 32.00086 31.99914 32.00086l306.752748 0-106.112189 104.959656c-12.576374 12.447359-12.672705 32.671738-0.25631 45.248112 6.239161 6.335493 14.496116 9.504099 22.751351 9.504099 8.12794 0 16.25588-3.103239 22.496761-9.247789l160.25545-158.495686C735.328262 526.592447 737.72794 512.767209 732.959548 501.152426z"
                  fill="#b11624" p-id="3424"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="bottom1">
          <div class="intro">{{ item.intro }}</div>
          <div class="img"><img :src="item.icon" alt=""></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- 备案 -->
      <div class="bei">
        <p class="an">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2024267351号-3</a>&nbsp;
          <span>服务生成的所有内容均由人工智能模型生成，其生成内容的准确性和完整性无法保证，不代表我们的态度或观点</span>
        </p>
      </div>
    </div>
    <div class="bottom2"></div>
  </div>
</template>
<script setup>
import { ref, reactive, watchEffect, onMounted, nextTick } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';
let isShowRecently = ref(false)
let tiptext = ref('验证码错误')
// let tiptext = ref('请输入正确的手机号码')
let isWei = ref(true)
let isLogin = ref(false)
let nickname = ref('小米')
let phone = ref('12345678910')
const appId = 'wxa4a6a28a70e6686a';
const redirectUri = 'https://www.enzhu-ai.com/';
// 用于存储显示二维码的容器引用
const loginContainer = ref(null);
let code = ref('')
let state = ref('')
let aactiveindex = ref(0)
let isShowtip = ref(false)
let text = ref('获取验证码')
let time = ref(60)
let isSend = ref(false)
let pnum = ref('')
let pnum1 = ref('')
let ynum = ref('')
let ynum1 = ref('')
let isScanSuccessful = ref(false)
let isCheck = ref(true)
let token = ref('')
const tablist = reactive([{
  id: 1,
  title: "法律",
  en: 'LEGAL ISSUES',
  name: "MainView",
  intro: '这里是关于生活AI小助手在法律方面的亮点的描述,可以吹大一点，让人觉得小助手在法律方面能够给到他们有用的帮助!',
  icon: require("../assets/item-law.png"),
  path: '/MainView'
}, {
  id: 2,
  title: "医疗",
  en: 'LEGAL ISSUES',
  name: "MedicalView",
  intro: '这里是关于生活AI小助手在医疗方面的亮点的描述可以吹大一点，让人觉得小助手在医疗方面能够给到他们有用的帮助!',
  icon: require("../assets/item-medic.png"),
  // path: '/MedicalView'
  path: ''
}, {
  id: 3,
  title: "教育",
  en: 'LEGAL ISSUES',
  name: "EducationView",
  intro: '这里是关于生活AI小助手在教育方面的亮点的描述，可以吹大一点，让人觉得小助手在教育方面能够给到他们有用的帮助!',
  icon: require("../assets/item-education.png"),
  path: '/EducationView'
  // path: ''
}])
const tab = reactive([{
  id: 0,
  title: '微信登录'
}, {
  id: 1,
  title: '验证码登录'
}])
let isShowMask = ref(false)
const router = useRouter();
function goPage(path) {
  console.log(111, path);
  if (path.trim().length != 0) {
    router.push(path);
  } else {
    // alert('近期上线')
    isShowRecently.value = true
  }
}
function clo() {
  isShowMask.value = false
}
// 初始化 WxLogin 对象
function initializeWxLogin() {
  const state = 'STATE';
  const scope = 'snsapi_login';
  // 实例化 WxLogin 对象
  const obj = new WxLogin({
    self_redirect: false,
    id: "login_container",
    appid: appId,
    scope: scope,
    redirect_uri: redirectUri,
    state: state,
    style: '',
    // href: "https://www.enzhu-ai.com/css/app.502af4f3.css",
    href: "https://www.enzhu-ai.com/css/custom-style.css",
    stylelite: ''
  });
}
function login() {
  isShowMask.value = true
  nextTick(() => {
    loadScript("https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js", initializeWxLogin);
  });
}
function chose(i) {
  aactiveindex.value = i
  isWei.value = !isWei.value
  if (isWei.value) {
    nextTick(() => {
      loadScript("https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js", initializeWxLogin);
    });
  }
}
function changeIscheck() {
  isCheck.value = !isCheck.value
  if (!isCheck.value) {
    tiptext.value = '请先同意并勾选许可协议和隐私政策'
    isShowtip.value = true
    setTimeout(() => {
      isShowtip.value = false
    }, 2000)
  }
}
function del() {
  pnum.value = ''
}
function del2() {
  ynum.value = ''
}

function send() {
  console.log(112, pnum.value);
  console.log(12345, isValidChinaPhoneNumber(pnum.value));

  // 获取验证码
  if (!isValidChinaPhoneNumber(pnum.value)) {
    tiptext.value = '请输入正确的手机号码'
    isShowtip.value = true
    setTimeout(() => {
      isShowtip.value = false
    }, 2000)
  } else {
    // 获取验证码
    console.log(111);
    isSend.value = true
    time.value = 59
    countDown()

    axios.post('https://login.enzhu-ai.com/api/send-sms', {
      params: {
        phone_number: pnum.value,
      },
    })
      .then(response => {
        // 处理后端返回的数据
        console.log(1234567, response.data)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}

async function countDown() {
  await new Promise((resolve) => {
    const intervalId = setInterval(() => {
      time.value -= 1
      if (time.value <= 0) {
        clearInterval(intervalId)
        isSend.value = false
        text.value = '重新发送'
        resolve()
      }
    }, 1000)
  })
}
// 动态加载外部脚本
function loadScript(src, callback) {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    callback();
  };
  document.head.appendChild(script);
}
// 验证手机号
function isValidChinaPhoneNumber(phoneNumber) {
  const regex = /^(1(3[0-9]|4[5-9]|5[0-3,5-9]|6[2,5,6,7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8})$/;
  return regex.test(phoneNumber);
}

function yzmlogin() {
  console.log(112, pnum.value);
  console.log(12345, isValidChinaPhoneNumber(pnum.value));
  if (!isValidChinaPhoneNumber(pnum.value)) {
    tiptext.value = '请输入正确的手机号码'
    isShowtip.value = true
    setTimeout(() => {
      isShowtip.value = false
    }, 2000)
  } else {
    axios.post('https://login.enzhu-ai.com/api/verify-sms', {
      params: {
        phone_number: pnum.value,
        code: ynum.value
      },
    })
      .then(response => {
        // 处理后端返回的数据
        console.log(1234567, response.data);
        if (response.data.status == 'success') {
          token.value = response.data.token
          localStorage.setItem('TOKEN', response.data.token)
          localStorage.setItem('PHONE', pnum.value)
          isShowMask.value = false
          isLogin.value = true
          phone.value = pnum.value
        } else {
          tiptext.value = response.data.message
          isShowtip.value = true
          setTimeout(() => {
            isShowtip.value = false
          }, 2000)
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}

function clorecently() {
  isShowRecently.value = false
}

onMounted(() => {
  let pn = localStorage.getItem('PHONE')
  if (pn) {
    phone.value = pn
    isLogin.value = true
  }
}),

  window.onload = function () {
    // 获取当前页面的完整URL
    // var currentUrl = window.location.href
    // console.log('Current URL:', currentUrl);
    const urlParams = new URLSearchParams(window.location.search);
    code.value = urlParams.get('code');
    state.value = urlParams.get('state');
    console.log(123, code.value);
    console.log(456, state.value);

    if (code.value && state.value) {
      console.log('发送code');
      // 发送GET请求
      axios.get('https://login.enzhu-ai.com/api/wechat-login', {
        params: {
          code: code.value,
          state: state.value
        }
      })
        .then(response => {
          // 处理后端返回的数据
          console.log(1234567, response.data);
          if (response.data.code == 200) {
            nickname.value = response.data.user.username
            phone.value = response.data.user.phone
            token.value = response.data.token
            localStorage.setItem('TOKEN', response.data.token)
          } else {
            isScanSuccessful.value = true
            isShowMask.value = true
            token.value = response.data.temp_token
            localStorage.setItem('TOKEN', response.data.temp_token)
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

  };
</script>
<style lang="less" scoped>
/* 超小屏样式 */
@media (max-width: 600px) {
  .bg {
    width: 100vw;
    height: 100vh;
    background-color: #f6f7fb;
    position: relative;
    overflow: hidden;

    .top {
      height: 280px;
      background-image: url(../assets/bg.png);
      background-size: cover;
      /* 背景图片固定不滚动 */
      background-attachment: fixed;
      /* 背景图片位置居中 */
      background-position: center center;
      /* 不重复背景图片 */
      background-repeat: no-repeat;

      .ttop {
        width: 98%;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .img {
            width: 30px;
            height: 30px;
            background-color: #5871d6;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            margin-left: 5px;
            font-size: 13px;
            color: #fff;
          }
        }

        .right {
          font-size: 13px;
          display: flex;
          align-items: center;

          .login {
            background-color: rgba(173, 170, 170, 0.5);
            color: #fff;
            width: 70px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            margin-right: 8px;
            cursor: pointer;
          }

          .register {
            background-color: #fe3532;
            color: #fff;
            width: 60px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            cursor: pointer;
          }
        }
      }

      .tbottom {
        margin-top: 50px;
        width: 100%;

        .title {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          font-size: 28px;
          color: #fff;
        }

        .intro {
          margin: 0 auto;
          color: #fff;
          font-size: 13px;
          width: 100%;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .middle {
      position: absolute;
      top: 200px;
      left: 5%;
      display: flex;
      width: 90%;
      margin: 0 auto;
      justify-content: space-around;
      overflow: hidden;

      .item {
        width: 100px;
        height: 320px;
        border: 1px solid #ddd;
        background-image: url(../assets/itembg.png);
        background-size: cover;
        /* 背景图片固定不滚动 */
        background-attachment: fixed;
        /* 背景图片位置居中 */
        background-position: center center;
        /* 不重复背景图片 */
        background-repeat: no-repeat;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;


        .top12 {
          width: 100%;
          height: 80px;
          overflow: hidden;

          .top1 {
            height: 65px;
            width: 90%;
            margin: 0 auto;
            margin-top: 10px;
            border-radius: 0;
            border-bottom: 1px solid #B6B6B6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
              .title {
                font-size: 24px;
                color: #000;
              }

              .en {
                color: #999;
                font-size: 10px;
                margin-top: 5px;
              }
            }

            .right {
              width: 30px;
              height: 30px;

              svg {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }

        .bottom1 {
          width: 90%;
          margin: 0 auto;

          .intro {
            font-size: 12px;
            color: #7d7d89;
            margin-top: 10px;
          }

          .img {
            width: 50px;
            height: 50px;
            float: right;
            margin-top: 30px;
            margin-right: 0px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .bottom {
      position: absolute;
      width: 100%;
      bottom: 10px;
      height: 10px;
      overflow: hidden;

      .bei {
        width: 500px;
        margin: 0 auto;

        .an {
          width: 612px;
          font-size: 6px;
          margin: 0 auto;
          color: #888;

          a {
            text-decoration: none;
            color: #888;
          }
        }
      }
    }

    .bottom2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-image: linear-gradient(to right, #9fe7f6, #b1d2e3, #ceb6c3, #ec9a9e, #fe96ad, #f6a7de, #f7b1fe);
    }
  }

  .mask {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    background-color: rgba(123, 122, 122, 0.5);
    top: 0;
    left: 0;
    z-index: 100;

    .loginbox {
      width: 100%;
      position: absolute;
      top: 120px;

      .login {
        width: 300px;
        height: 400px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .clo {
          position: absolute;
          right: 10px;
          width: 20px;
          height: 20px;
          top: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .p1 {
          width: 100%;
          text-align: center;
          margin-top: 30px;
          font-size: 22px;
          font-weight: bolder;

          span {
            font-size: 28px;
            font-weight: bolder;
          }
        }

        .verbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 40px;

          .verify {
            position: absolute;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            align-items: center;


            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }
        }


        .tab {
          display: flex;
          height: 40px;
          line-height: 50px;
          margin: 0 auto;
          width: 200px;
          justify-content: space-around;
          font-size: 16px;

          .tabitem {
            cursor: pointer;

            &.active {
              font-weight: bolder;
              border-bottom: 1px solid #FF3B3B;
            }
          }
        }

        .p2 {
          width: 100%;
          text-align: center;
          color: #999;
          font-size: 12px;
          margin-top: 15px;
          margin-bottom: 5px;
        }

        .code {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          margin-top: 20px;
          position: relative;

          .login_container {
            width: 100px;
            height: 100px;
            background-color: #fe3532;

            img {
              width: 100%;
              height: 100%;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          .codemask {
            width: 102px;
            font-size: 11px;
            height: 102px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);
            position: absolute;
            top: 0;
            text-align: center;
            overflow: hidden;

            .text1 {
              margin-top: 30px;
            }
          }
        }

        .bot {
          display: flex;
          width: 162px;
          margin: 0 auto;
          margin-top: 15px;

          .img {
            width: 16px;
            height: 13px;
            margin-right: 2px;
            flex-shrink: 0;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            font-size: 12px;
            color: #999;
          }
        }

        form {
          width: 80%;
          margin: 0 auto;
          margin-top: 30px;
          position: relative;

          input {
            &:first-child {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }

            &:nth-child(2) {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }
          }

          .pdel {
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .get {
            position: absolute;
            right: 0;
            top: 60px;
            outline: none;
            border: none;
            background-color: transparent;
            font-size: 14px;
            cursor: pointer;
            color: #666;

            &.active {
              color: #ff0000;
            }
          }

          .countdown {
            position: absolute;
            right: 0;
            top: 50px;
            color: #666;
            display: flex;
            font-size: 14px;
            width: 50px;
            justify-content: flex-end;

            .ydel {
              margin-right: 10px;
              cursor: pointer;
            }
          }

          button[type=submit] {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #E23030;
            opacity: .4;
            outline: none;
            border: none;
            color: #fff;

            &.active {
              opacity: 1;
            }
          }

          .end {
            color: #666;
            font-size: 14px;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            cursor: pointer;
          }
        }

        .privicy {
          width: 88%;
          height: 40px;
          margin: 0 auto;
          display: flex;
          margin-top: 45px;
          position: relative;

          .check {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            border: 1px solid #979797;
            overflow: hidden;
            flex-shrink: 0;
            position: absolute;
            top: 1px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;

            }
          }

          p {
            font-size: 12px;
            margin-left: 18px;

            a {
              text-decoration: none;
              color: #FF0000;
            }
          }

          .grey {
            padding: 6px;
            background-color: #555;
            color: #fff;
            position: absolute;
            font-size: 13px;
            box-shadow: 0px 3px 6px 0px rgba(176, 156, 156, 0.5);
            border-radius: 3px;
            top: 35px;
          }

          .triangle-up {
            position: absolute;
            top: 30px;
            left: 2px;
            z-index: 99;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #555;
          }
        }
      }

      .phonebox {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .phone {
          width: 80%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;

          .clo {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            top: 10px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .verify {
            position: absolute;
            width: 100px;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            align-items: center;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            left: 35%;

            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }

          .title {
            font-size: 22px;
            font-weight: bolder;
            margin-top: 35px;
          }

          .intro {
            font-size: 12px;
            color: #888;
            margin-top: 18px;
          }

          form {
            margin-top: 30px;
            position: relative;

            input {
              &:first-child {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }

              &:nth-child(2) {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }
            }

            .pdel {
              position: absolute;
              top: 2px;
              right: 0;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .get {
              position: absolute;
              right: 0;
              top: 50px;
              outline: none;
              border: none;
              background-color: transparent;
              font-size: 14px;
              cursor: pointer;
              color: #666;

              &.active {
                color: #ff0000;
              }
            }

            .countdown {
              position: absolute;
              right: 0;
              top: 50px;
              color: #666;
              display: flex;
              font-size: 14px;
              width: 50px;
              justify-content: flex-end;

              .ydel {
                margin-right: 10px;
                cursor: pointer;
              }
            }

            button[type=submit] {
              width: 100%;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              background-color: #E23030;
              opacity: .4;
              outline: none;
              border: none;
              color: #fff;

              &.active {
                opacity: 1;
              }
            }

            .end {
              color: #666;
              font-size: 14px;
              width: 100%;
              text-align: center;
              margin-top: 15px;
              cursor: pointer;
            }
          }
        }

      }
    }
  }

  .recently {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 100;



    .retext {
      width: 200px;
      height: 100px;
      margin: 0 auto;
      text-align: center;
      line-height: 100px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      border: 1px solid #d5d5d5;

      .guan {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 22px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rtext {
        font-size: 22px;
      }
    }
  }
}

/* 小屏样式 */
@media (min-width: 600px) and (max-width: 960px) {
  .bg {
    width: 100vw;
    height: 100vh;
    background-color: #f6f7fb;
    position: relative;

    .top {
      height: 440px;
      background-image: url(../assets/bg.png);
      background-size: contain;
      background-repeat: no-repeat;

      .ttop {
        width: 98%;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            margin-left: 5px;
            font-size: 13px;
            color: #fff;
          }
        }

        .right {
          font-size: 13px;
          display: flex;
          align-items: center;

          .login {
            background-color: rgba(173, 170, 170, 0.5);
            color: #fff;
            width: 70px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            margin-right: 8px;
            cursor: pointer;
          }

          .register {
            background-color: #fe3532;
            color: #fff;
            width: 60px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            cursor: pointer;
          }
        }

        .right2 {
          font-size: 13px;
          display: flex;
          align-items: center;
          color: #fff;

          .nickname {
            margin-right: 5px;
          }

          /*.phone {}*/
        }
      }

      .tbottom {
        margin-top: 50px;
        width: 100%;

        .title {
          width: 400px;
          margin: 0 auto;
          text-align: center;
          font-size: 28px;
          color: #fff;
        }

        .intro {
          margin: 0 auto;
          color: #fff;
          font-size: 13px;
          width: 300px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .middle {
      position: absolute;
      top: 250px;
      left: 10%;
      display: flex;
      width: 80%;
      margin: 0 auto;
      justify-content: space-around;
      overflow: hidden;

      .item {
        z-index: 99;
        width: 300px;
        height: 340px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;


        .top12 {
          width: 100%;
          height: 109px;
          overflow: hidden;
          background-image: linear-gradient(to bottom, #ffe3e0, rgb(247, 238, 240));

          .top1 {
            height: 65px;
            width: 90%;
            margin: 0 auto;
            margin-top: 20px;
            border-radius: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #B6B6B6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
              .title {
                font-size: 36px;
                color: #000;
              }

              .en {
                color: #999;
                font-size: 13px;
                margin-top: 5px;
              }
            }

            .right {
              width: 40px;
              height: 40px;

              svg {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }

        .bottom1 {
          width: 90%;
          margin: 0 auto;
          margin-top: 25px;

          .intro {
            font-size: 14px;
            color: #7d7d89;
            margin-top: 10px;
          }

          .img {
            width: 100px;
            height: 100px;
            float: right;
            margin-top: 30px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .bottom {
      position: absolute;
      width: 100%;
      bottom: 15px;
      height: 15px;
      overflow: hidden;

      .bei {
        width: 500px;
        margin: 0 auto;

        .an {
          width: 612px;
          font-size: 10px;
          margin: 0 auto;
          color: #888;

          a {
            text-decoration: none;
            color: #888;
          }
        }
      }
    }

    .bottom2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-image: linear-gradient(to right, #9fe7f6, #b1d2e3, #ceb6c3, #ec9a9e, #fe96ad, #f6a7de, #f7b1fe);
    }
  }

  .mask {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    background-color: rgba(123, 122, 122, 0.5);
    top: 0;
    left: 0;
    z-index: 100;

    .loginbox {
      width: 100%;
      position: absolute;
      top: 120px;

      .login {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .clo {
          position: absolute;
          right: 10px;
          width: 20px;
          height: 20px;
          top: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .p1 {
          width: 100%;
          text-align: center;
          margin-top: 30px;
          font-size: 22px;
          font-weight: bolder;

          span {
            font-size: 28px;
            font-weight: bolder;
          }
        }

        .verbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 40px;

          .verify {
            position: absolute;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            align-items: center;


            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }
        }


        .tab {
          display: flex;
          height: 40px;
          line-height: 50px;
          margin: 0 auto;
          width: 200px;
          justify-content: space-around;

          .tabitem {
            cursor: pointer;

            &.active {
              font-weight: bolder;
              border-bottom: 1px solid #FF3B3B;
            }
          }
        }

        .p2 {
          width: 100%;
          text-align: center;
          color: #999;
          font-size: 12px;
          margin-top: 15px;
          margin-bottom: 5px;
        }

        .code {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          margin-top: 20px;
          position: relative;

          .login_container {
            width: 100px;
            height: 100px;
            background-color: #fe3532;

            img {
              width: 100%;
              height: 100%;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          .codemask {
            width: 102px;
            font-size: 11px;
            height: 102px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);
            position: absolute;
            top: 0;
            text-align: center;
            overflow: hidden;

            .text1 {
              margin-top: 30px;
            }
          }
        }

        .bot {
          display: flex;
          width: 162px;
          margin: 0 auto;
          margin-top: 15px;

          .img {
            width: 16px;
            height: 13px;
            margin-right: 2px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            font-size: 12px;
            color: #999;
          }
        }

        form {
          width: 80%;
          margin: 0 auto;
          margin-top: 30px;
          position: relative;

          input {
            &:first-child {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }

            &:nth-child(2) {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }
          }

          .pdel {
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .get {
            position: absolute;
            right: 0;
            top: 50px;
            outline: none;
            border: none;
            background-color: transparent;
            color: #666;
            font-size: 14px;
            cursor: pointer;

            &.active {
              color: #ff0000;
            }
          }

          .countdown {
            position: absolute;
            right: 0;
            top: 50px;
            color: #666;
            display: flex;
            font-size: 14px;
            width: 50px;
            justify-content: flex-end;

            .ydel {
              margin-right: 10px;
              cursor: pointer;
            }
          }

          button[type=submit] {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #E23030;
            opacity: .4;
            outline: none;
            border: none;
            color: #fff;

            &.active {
              opacity: 1;
            }
          }

          .end {
            color: #666;
            font-size: 14px;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            cursor: pointer;
          }
        }

        .privicy {
          width: 88%;
          height: 40px;
          margin: 0 auto;
          display: flex;
          margin-top: 45px;
          position: relative;

          .check {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            border: 1px solid #979797;
            overflow: hidden;
            flex-shrink: 0;
            position: absolute;
            top: 1px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;

            }
          }

          p {
            font-size: 12px;
            margin-left: 18px;

            a {
              text-decoration: none;
              color: #FF0000;
            }
          }

          .grey {
            padding: 6px;
            background-color: #555;
            color: #fff;
            position: absolute;
            font-size: 13px;
            box-shadow: 0px 3px 6px 0px rgba(176, 156, 156, 0.5);
            border-radius: 3px;
            top: 35px;
          }

          .triangle-up {
            position: absolute;
            top: 30px;
            left: 2px;
            z-index: 99;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #555;
          }
        }
      }

      .phonebox {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .phone {
          width: 80%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;

          .clo {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            top: 10px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .verify {
            position: absolute;
            width: 100px;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            align-items: center;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            left: 35%;

            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }

          .title {
            font-size: 22px;
            font-weight: bolder;
            margin-top: 35px;
          }

          .intro {
            font-size: 12px;
            color: #888;
            margin-top: 18px;
          }

          form {
            margin-top: 30px;
            position: relative;

            input {
              &:first-child {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }

              &:nth-child(2) {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }
            }

            .pdel {
              position: absolute;
              top: 2px;
              right: 0;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .get {
              position: absolute;
              right: 0;
              top: 50px;
              outline: none;
              border: none;
              background-color: transparent;
              color: #666;
              font-size: 14px;
              cursor: pointer;

              &.active {
                color: #ff0000;
              }
            }

            .countdown {
              position: absolute;
              right: 0;
              top: 50px;
              color: #666;
              display: flex;
              font-size: 14px;
              width: 50px;
              justify-content: flex-end;

              .ydel {
                margin-right: 10px;
                cursor: pointer;
              }
            }

            button[type=submit] {
              width: 100%;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              background-color: #E23030;
              opacity: .4;
              outline: none;
              border: none;
              color: #fff;

              &.active {
                opacity: 1;
              }
            }

            .end {
              color: #666;
              font-size: 14px;
              width: 100%;
              text-align: center;
              margin-top: 15px;
              cursor: pointer;
            }
          }
        }

      }
    }

    .recently {}
  }

  .recently {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 100;



    .retext {
      width: 400px;
      height: 200px;
      margin: 0 auto;
      text-align: center;
      line-height: 200px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      border: 1px solid #d5d5d5;

      .guan {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 22px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rtext {
        font-size: 22px;
      }
    }
  }
}

/* 中屏样式 */
@media (min-width: 960px) and (max-width: 1280px) {
  .bg {
    width: 100vw;
    height: 100vh;
    background-color: #f6f7fb;
    position: relative;

    .top {
      height: 440px;
      background-image: url(../assets/bg.png);
      background-size: contain;
      background-repeat: no-repeat;

      .ttop {
        width: 98%;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            margin-left: 5px;
            font-size: 13px;
            color: #fff;
          }
        }

        .right {
          font-size: 13px;
          display: flex;
          align-items: center;

          .login {
            background-color: rgba(173, 170, 170, 0.5);
            color: #fff;
            width: 70px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            margin-right: 8px;
            cursor: pointer;
          }

          .register {
            background-color: #fe3532;
            color: #fff;
            width: 60px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            cursor: pointer;
          }
        }

        .right2 {
          font-size: 13px;
          display: flex;
          align-items: center;
          color: #fff;

          .nickname {
            margin-right: 5px;
          }

          /*.phone {}*/
        }
      }

      .tbottom {
        margin-top: 50px;
        width: 100%;

        .title {
          width: 400px;
          margin: 0 auto;
          text-align: center;
          font-size: 28px;
          color: #fff;
        }

        .intro {
          margin: 0 auto;
          color: #fff;
          font-size: 13px;
          width: 300px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .middle {
      position: absolute;
      top: 250px;
      left: 10%;
      display: flex;
      width: 80%;
      margin: 0 auto;
      justify-content: space-around;
      overflow: hidden;

      .item {
        z-index: 99;
        width: 300px;
        height: 340px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;


        .top12 {
          width: 100%;
          height: 109px;
          overflow: hidden;
          background-image: linear-gradient(to bottom, #ffe3e0, rgb(247, 238, 240));

          .top1 {
            height: 65px;
            width: 90%;
            margin: 0 auto;
            margin-top: 20px;
            border-radius: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #B6B6B6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
              .title {
                font-size: 36px;
                color: #000;
              }

              .en {
                color: #999;
                font-size: 13px;
                margin-top: 5px;
              }
            }

            .right {
              width: 40px;
              height: 40px;

              svg {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }

        .bottom1 {
          width: 90%;
          margin: 0 auto;
          margin-top: 25px;

          .intro {
            font-size: 14px;
            color: #7d7d89;
            margin-top: 10px;
          }

          .img {
            width: 100px;
            height: 100px;
            float: right;
            margin-top: 30px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .bottom {
      position: absolute;
      width: 100%;
      bottom: 15px;
      height: 15px;
      overflow: hidden;

      .bei {
        width: 500px;
        margin: 0 auto;

        .an {
          width: 612px;
          font-size: 10px;
          margin: 0 auto;
          color: #888;

          a {
            text-decoration: none;
            color: #888;
          }
        }
      }
    }

    .bottom2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-image: linear-gradient(to right, #9fe7f6, #b1d2e3, #ceb6c3, #ec9a9e, #fe96ad, #f6a7de, #f7b1fe);
    }
  }

  .mask {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    background-color: rgba(123, 122, 122, 0.5);
    top: 0;
    left: 0;
    z-index: 100;

    .loginbox {
      width: 100%;
      position: absolute;
      top: 120px;

      .login {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .clo {
          position: absolute;
          right: 10px;
          width: 20px;
          height: 20px;
          top: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .p1 {
          width: 100%;
          text-align: center;
          margin-top: 30px;
          font-size: 22px;
          font-weight: bolder;

          span {
            font-size: 28px;
            font-weight: bolder;
          }
        }

        .verbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 40px;

          .verify {
            position: absolute;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            align-items: center;


            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }
        }


        .tab {
          display: flex;
          height: 40px;
          line-height: 50px;
          margin: 0 auto;
          width: 200px;
          justify-content: space-around;

          .tabitem {
            cursor: pointer;

            &.active {
              font-weight: bolder;
              border-bottom: 1px solid #FF3B3B;
            }
          }
        }

        .p2 {
          width: 100%;
          text-align: center;
          color: #999;
          font-size: 12px;
          margin-top: 15px;
          margin-bottom: 5px;
        }

        .code {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          margin-top: 20px;
          position: relative;

          .login_container {
            width: 100px;
            height: 100px;
            background-color: #fe3532;

            img {
              width: 100%;
              height: 100%;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          .codemask {
            width: 102px;
            font-size: 11px;
            height: 102px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);
            position: absolute;
            top: 0;
            text-align: center;
            overflow: hidden;

            .text1 {
              margin-top: 30px;
            }
          }
        }

        .bot {
          display: flex;
          width: 162px;
          margin: 0 auto;
          margin-top: 15px;

          .img {
            width: 16px;
            height: 13px;
            margin-right: 2px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            font-size: 12px;
            color: #999;
          }
        }

        form {
          width: 80%;
          margin: 0 auto;
          margin-top: 30px;
          position: relative;

          input {
            &:first-child {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }

            &:nth-child(2) {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }
          }

          .pdel {
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .get {
            position: absolute;
            right: 0;
            top: 50px;
            outline: none;
            border: none;
            background-color: transparent;
            color: #666;
            font-size: 14px;
            cursor: pointer;

            &.active {
              color: #ff0000;
            }
          }

          .countdown {
            position: absolute;
            right: 0;
            top: 50px;
            color: #666;
            display: flex;
            font-size: 14px;
            width: 50px;
            justify-content: flex-end;

            .ydel {
              margin-right: 10px;
              cursor: pointer;
            }
          }

          button[type=submit] {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #E23030;
            opacity: .4;
            outline: none;
            border: none;
            color: #fff;

            &.active {
              opacity: 1;
            }
          }

          .end {
            color: #666;
            font-size: 14px;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            cursor: pointer;
          }
        }

        .privicy {
          width: 88%;
          height: 40px;
          margin: 0 auto;
          display: flex;
          margin-top: 45px;
          position: relative;

          .check {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            border: 1px solid #979797;
            overflow: hidden;
            flex-shrink: 0;
            position: absolute;
            top: 1px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;

            }
          }

          p {
            font-size: 12px;
            margin-left: 18px;

            a {
              text-decoration: none;
              color: #FF0000;
            }
          }

          .grey {
            padding: 6px;
            background-color: #555;
            color: #fff;
            position: absolute;
            font-size: 13px;
            box-shadow: 0px 3px 6px 0px rgba(176, 156, 156, 0.5);
            border-radius: 3px;
            top: 35px;
          }

          .triangle-up {
            position: absolute;
            top: 30px;
            left: 2px;
            z-index: 99;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #555;
          }
        }
      }

      .phonebox {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .phone {
          width: 80%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;

          .clo {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            top: 10px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .verify {
            position: absolute;
            width: 100px;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            align-items: center;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            left: 35%;

            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }

          .title {
            font-size: 22px;
            font-weight: bolder;
            margin-top: 35px;
          }

          .intro {
            font-size: 12px;
            color: #888;
            margin-top: 18px;
          }

          form {
            margin-top: 30px;
            position: relative;

            input {
              &:first-child {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }

              &:nth-child(2) {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }
            }

            .pdel {
              position: absolute;
              top: 2px;
              right: 0;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .get {
              position: absolute;
              right: 0;
              top: 50px;
              outline: none;
              border: none;
              background-color: transparent;
              color: #666;
              font-size: 14px;
              cursor: pointer;

              &.active {
                color: #ff0000;
              }
            }

            .countdown {
              position: absolute;
              right: 0;
              top: 50px;
              color: #666;
              display: flex;
              font-size: 14px;
              width: 50px;
              justify-content: flex-end;

              .ydel {
                margin-right: 10px;
                cursor: pointer;
              }
            }

            button[type=submit] {
              width: 100%;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              background-color: #E23030;
              opacity: .4;
              outline: none;
              border: none;
              color: #fff;

              &.active {
                opacity: 1;
              }
            }

            .end {
              color: #666;
              font-size: 14px;
              width: 100%;
              text-align: center;
              margin-top: 15px;
              cursor: pointer;
            }
          }
        }

      }
    }

    .recently {}
  }

  .recently {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 100;



    .retext {
      width: 400px;
      height: 200px;
      margin: 0 auto;
      text-align: center;
      line-height: 200px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      border: 1px solid #d5d5d5;

      .guan {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 22px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rtext {
        font-size: 22px;
      }
    }
  }
}

/* 大屏样式 */
@media (min-width: 1280px) {
  .bg {
    width: 100vw;
    height: 100vh;
    background-color: #f6f7fb;
    position: relative;

    .top {
      height: 440px;
      background-image: url(../assets/bg.png);
      background-size: contain;
      background-repeat: no-repeat;

      .ttop {
        width: 98%;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            margin-left: 5px;
            font-size: 13px;
            color: #fff;
          }
        }

        .right {
          font-size: 13px;
          display: flex;
          align-items: center;

          .login {
            background-color: rgba(173, 170, 170, 0.5);
            color: #fff;
            width: 70px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            margin-right: 8px;
            cursor: pointer;
          }

          .register {
            background-color: #fe3532;
            color: #fff;
            width: 60px;
            height: 20px;
            border-radius: 5px;
            text-align: center;
            line-height: 20px;
            border: 1px solid #ddd;
            padding: 5px;
            cursor: pointer;
          }
        }

        .right2 {
          font-size: 13px;
          display: flex;
          align-items: center;
          color: #fff;

          .nickname {
            margin-right: 5px;
          }

          /*.phone {}*/
        }
      }

      .tbottom {
        margin-top: 50px;
        width: 100%;

        .title {
          width: 400px;
          margin: 0 auto;
          text-align: center;
          font-size: 28px;
          color: #fff;
        }

        .intro {
          margin: 0 auto;
          color: #fff;
          font-size: 13px;
          width: 300px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .middle {
      position: absolute;
      top: 250px;
      left: 10%;
      display: flex;
      width: 80%;
      margin: 0 auto;
      justify-content: space-around;
      overflow: hidden;

      .item {
        z-index: 99;
        width: 300px;
        height: 340px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;


        .top12 {
          width: 100%;
          height: 109px;
          overflow: hidden;
          background-image: linear-gradient(to bottom, #ffe3e0, rgb(247, 238, 240));

          .top1 {
            height: 65px;
            width: 90%;
            margin: 0 auto;
            margin-top: 20px;
            border-radius: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #B6B6B6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
              .title {
                font-size: 36px;
                color: #000;
              }

              .en {
                color: #999;
                font-size: 13px;
                margin-top: 5px;
              }
            }

            .right {
              width: 40px;
              height: 40px;

              svg {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }

        .bottom1 {
          width: 90%;
          margin: 0 auto;
          margin-top: 25px;

          .intro {
            font-size: 14px;
            color: #7d7d89;
            margin-top: 10px;
          }

          .img {
            width: 100px;
            height: 100px;
            float: right;
            margin-top: 30px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .bottom {
      position: absolute;
      width: 100%;
      bottom: 15px;
      height: 15px;
      overflow: hidden;

      .bei {
        width: 500px;
        margin: 0 auto;

        .an {
          width: 612px;
          font-size: 10px;
          margin: 0 auto;
          color: #888;

          a {
            text-decoration: none;
            color: #888;
          }
        }
      }
    }

    .bottom2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-image: linear-gradient(to right, #9fe7f6, #b1d2e3, #ceb6c3, #ec9a9e, #fe96ad, #f6a7de, #f7b1fe);
    }
  }

  .mask {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    background-color: rgba(123, 122, 122, 0.5);
    top: 0;
    left: 0;
    z-index: 100;

    .loginbox {
      width: 100%;
      position: absolute;
      top: 120px;

      .login {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .clo {
          position: absolute;
          right: 10px;
          width: 20px;
          height: 20px;
          top: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .p1 {
          width: 100%;
          text-align: center;
          margin-top: 30px;
          font-size: 22px;
          font-weight: bolder;

          span {
            font-size: 28px;
            font-weight: bolder;
          }
        }

        .verbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 40px;

          .verify {
            position: absolute;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            align-items: center;


            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }
        }


        .tab {
          display: flex;
          height: 40px;
          line-height: 50px;
          margin: 0 auto;
          width: 200px;
          justify-content: space-around;

          .tabitem {
            cursor: pointer;

            &.active {
              font-weight: bolder;
              border-bottom: 1px solid #FF3B3B;
            }
          }
        }

        .p2 {
          width: 100%;
          text-align: center;
          color: #999;
          font-size: 12px;
          margin-top: 15px;
          margin-bottom: 5px;
        }

        .code {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          margin-top: 20px;
          position: relative;

          .login_container {
            width: 100px;
            height: 100px;
            background-color: #fe3532;

            img {
              width: 100%;
              height: 100%;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          .codemask {
            width: 102px;
            font-size: 11px;
            height: 102px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);
            position: absolute;
            top: 0;
            text-align: center;
            overflow: hidden;

            .text1 {
              margin-top: 30px;
            }
          }
        }

        .bot {
          display: flex;
          width: 162px;
          margin: 0 auto;
          margin-top: 15px;

          .img {
            width: 16px;
            height: 13px;
            margin-right: 2px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            font-size: 12px;
            color: #999;
          }
        }

        form {
          width: 80%;
          margin: 0 auto;
          margin-top: 30px;
          position: relative;

          input {
            &:first-child {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }

            &:nth-child(2) {
              border: none;
              outline: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 20px;
              width: 100%;
              padding: 5px;
              padding-left: 0;
              box-sizing: border-box;
              font-size: 14px;
              caret-color: #ff0000;

              &:focus {
                border-bottom: 1px solid #ff0000;
              }

              &::placeholder {
                color: #BBB;
                font-size: 14px;
              }
            }
          }

          .pdel {
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .get {
            position: absolute;
            right: 0;
            top: 50px;
            outline: none;
            border: none;
            background-color: transparent;
            font-size: 14px;
            cursor: pointer;
            color: #666;

            &.active {
              color: #ff0000;
            }
          }

          .countdown {
            position: absolute;
            right: 0;
            top: 50px;
            color: #666;
            display: flex;
            font-size: 14px;
            width: 50px;
            justify-content: flex-end;

            .ydel {
              margin-right: 10px;
              cursor: pointer;
            }
          }

          button[type=submit] {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #E23030;
            opacity: .4;
            outline: none;
            border: none;
            color: #fff;

            &.active {
              opacity: 1;
            }
          }

          .end {
            color: #666;
            font-size: 14px;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            cursor: pointer;
          }
        }

        .privicy {
          width: 88%;
          height: 40px;
          margin: 0 auto;
          display: flex;
          margin-top: 45px;
          position: relative;

          .check {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            border: 1px solid #979797;
            overflow: hidden;
            flex-shrink: 0;
            position: absolute;
            top: 1px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;

            }
          }

          p {
            font-size: 12px;
            margin-left: 18px;

            a {
              text-decoration: none;
              color: #FF0000;
            }
          }

          .grey {
            padding: 6px;
            background-color: #555;
            color: #fff;
            position: absolute;
            font-size: 13px;
            box-shadow: 0px 3px 6px 0px rgba(176, 156, 156, 0.5);
            border-radius: 3px;
            top: 35px;
          }

          .triangle-up {
            position: absolute;
            top: 30px;
            left: 2px;
            z-index: 99;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #555;
          }
        }
      }

      .phonebox {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #f7e5e5;
        margin: 0 auto;
        position: relative;

        .phone {
          width: 80%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;

          .clo {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            top: 10px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .verify {
            position: absolute;
            width: 100px;
            height: 22px;
            line-height: 22px;
            display: flex;
            background-color: #E23030;
            border-radius: 4px;
            align-items: center;
            margin-top: 10px;
            padding: 5px;
            padding-bottom: 3px;
            padding-left: 0;
            left: 35%;

            .ico {
              width: 25px;
              height: 25px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .err {
              font-size: 14px;
              color: #fff;
            }
          }

          .title {
            font-size: 22px;
            font-weight: bolder;
            margin-top: 35px;
          }

          .intro {
            font-size: 12px;
            color: #888;
            margin-top: 18px;
          }

          form {
            margin-top: 30px;
            position: relative;

            input {
              &:first-child {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }

              &:nth-child(2) {
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                width: 100%;
                padding: 5px;
                padding-left: 0;
                box-sizing: border-box;
                font-size: 14px;
                caret-color: #ff0000;

                &:focus {
                  border-bottom: 1px solid #ff0000;
                }

                &::placeholder {
                  color: #BBB;
                  font-size: 14px;
                }
              }
            }

            .pdel {
              position: absolute;
              top: 2px;
              right: 0;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .get {
              position: absolute;
              right: 0;
              top: 50px;
              outline: none;
              border: none;
              background-color: transparent;
              color: #666;
              font-size: 14px;
              cursor: pointer;

              &.active {
                color: #ff0000;
              }
            }

            .countdown {
              position: absolute;
              right: 0;
              top: 50px;
              color: #666;
              display: flex;
              font-size: 14px;
              width: 50px;
              justify-content: flex-end;

              .ydel {
                margin-right: 10px;
                cursor: pointer;
              }
            }

            button[type=submit] {
              width: 100%;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              background-color: #E23030;
              opacity: .4;
              outline: none;
              border: none;
              color: #fff;

              &.active {
                opacity: 1;
              }
            }

            .end {
              color: #666;
              font-size: 14px;
              width: 100%;
              text-align: center;
              margin-top: 15px;
              cursor: pointer;
            }
          }
        }

      }
    }

    .recently {}
  }

  .recently {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 100;



    .retext {
      width: 400px;
      height: 200px;
      margin: 0 auto;
      text-align: center;
      line-height: 200px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      border: 1px solid #d5d5d5;

      .guan {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 22px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rtext {
        font-size: 22px;
      }
    }
  }
}
</style>
