import { createStore } from "vuex";

interface State {
  haveques: boolean;
  nowList0: boolean;
  nowList1: boolean;
  nowList2: boolean;
  nowList3: boolean;
  nowList4: boolean;
  nowList5: boolean;
  isShowMask: boolean;
  isShowLawyer: boolean;
  isShowLogin: boolean;
  isShowFeedbackMask: boolean;
  isShowFeedback: boolean;
  isShowAbout: boolean;
  title: string;
  nli0: any[];
  nli1: any[];
  nli2: any[];
  nli3: any[];
  nli4: any[];
  nli5: any[];
}
export default createStore<State>({
  state: {
    haveques: false,
    nowList0: false,
    nowList1: false,
    nowList2: false,
    nowList3: false,
    nowList4: false,
    nowList5: false,
    isShowMask: false,
    isShowLawyer: false,
    isShowLogin: false,
    isShowFeedbackMask: false,
    isShowFeedback: false,
    isShowAbout: false,
    title: "法律咨询",
    nli0: [],
    nli1: [],
    nli2: [],
    nli3: [],
    nli4: [],
    nli5: [],
  },
  getters: {},
  mutations: {
    c0(state, o) {
      state.nli0.push(o);
    },
    c1(state, o) {
      state.nli1.push(o);
    },
    c2(state, o) {
      state.nli2.push(o);
    },
    c3(state, o) {
      state.nli3.push(o);
    },
    c4(state, o) {
      state.nli4.push(o);
    },
    c5(state, o) {
      state.nli5.push(o);
    },
    changeHaveques(state) {
      state.haveques = true;
    },

    c01(state) {
      state.nli0 = [];
    },
    c11(state) {
      state.nli1 = [];
    },
    c21(state) {
      state.nli2 = [];
    },

    changeTitle(state, title) {
      state.title = title;
    },
    changeno0(state) {
      state.nowList0 = true;
    },
    changeno01(state) {
      state.nowList0 = false;
    },
    changeno1(state) {
      state.nowList1 = true;
    },
    changeno11(state) {
      state.nowList1 = false;
    },
    changeno2(state) {
      state.nowList2 = true;
    },
    changeno21(state) {
      state.nowList2 = false;
    },
    changeno3(state) {
      state.nowList3 = true;
    },
    changeno31(state) {
      state.nowList3 = false;
    },
    changeno4(state) {
      // console.log(188, state.nowList4);
      state.nowList4 = true;
      // console.log(189, state.nowList4);
    },
    changeno41(state) {
      state.nowList4 = false;
    },
    changeno5(state) {
      // console.log(188, state.nowList5);
      state.nowList5 = true;
      // console.log(189, state.nowList5);
    },
    changeno51(state) {
      state.nowList5 = false;
    },
    changeIsShowMask(state) {
      state.isShowMask = true;
    },
    changeIsShowMask1(state) {
      state.isShowMask = false;
    },
    changeIsShowLawyer(state) {
      state.isShowLawyer = true;
    },
    changeIsShowLawyer1(state) {
      state.isShowLawyer = false;
    },
    changeIsShowLogin(state) {
      state.isShowLogin = true;
    },
    changeIsShowLogin1(state) {
      state.isShowLogin = false;
    },
    changeisShowFeedbackMask(state) {
      state.isShowFeedbackMask = true;
    },
    changeisShowFeedbackMask1(state) {
      state.isShowFeedbackMask = false;
    },
    changeIsShowFeedback(state) {
      state.isShowFeedback = true;
    },
    changeIsShowFeedback1(state) {
      state.isShowFeedback = false;
    },

    changeIsShowAbout(state) {
      state.isShowAbout = true;
    },
    changeIsShowAbout1(state) {
      state.isShowAbout = false;
    },
  },
  actions: {},
  modules: {},
});
