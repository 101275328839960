<template>
  <router-view />
</template>
<script setup>

</script>
<style lang="less">
* {
  margin: 0;
  padding: 0
}
</style>

<!-- 主题色 #ff4444 -->
<!-- 灰色 #bebebe -->
<!-- 黄色 #f4ea2a -->
<!-- 浅黑 #595555 -->
